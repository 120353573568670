




















import type { PropType } from 'vue';
import { defineComponent } from '@nuxtjs/composition-api';
import HeroBannerSlide, {
  HeroBannerSlideInterface
} from '~/components/molecules/HeroBannerSlide/HeroBannerSlide.vue';

export default defineComponent({
  name: 'HeroBanner',
  components: {
    HeroBannerSlide
  },
  props: {
    slides: {
      type: Array as PropType<Array<HeroBannerSlideInterface>>,
      required: true
    }
  }
});
