




import type { PropType } from 'vue';
import { defineComponent, computed } from '@nuxtjs/composition-api';
import {
  HeroBannerSlideInterface
} from '~/components/molecules/HeroBannerSlide/HeroBannerSlide.vue';
import HeroBanner from '~/components/organisms/HeroBanner/HeroBanner.vue';
import { CmsImageResponse } from '~/types/cms/CmsImageResponse';
import cmsImageToUrl from '@/helpers/cms/cmsImageToUrl';

type HeroBannerSlideCmsInterface = HeroBannerSlideInterface & {
  image: CmsImageResponse
}

export default defineComponent({
  name: 'HeroBannerCms',
  components: {
    HeroBanner
  },
  props: {
    slides: {
      type: Array as PropType<Array<HeroBannerSlideCmsInterface>>,
      required: true
    }
  },
  setup({ slides }) {
    const ampSlides = computed(() => slides.map(slide => ({
      ...slide,
      imageUrl: slide.image ? cmsImageToUrl(slide.image) : ''
    })));
    return ({ ampSlides });
  }
});
